import { Progress } from '@mantine/core';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useState } from 'react';

const TIMEOUT_TO_SHOW_PROGRESS = 250;
const PROGRESS_PERCENT_INCREMENT_ON_TICK = 3;
const PROGRESS_TICK_INTERVAL = 150;
const MAX_PROGRESS_PERCENT = 90;

export const RouteProgress = (): ReactNode => {
  const router = useRouter();
  const [isLoading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let progressInterval: NodeJS.Timeout;
    let progressTimeout: NodeJS.Timeout;

    let routeRedirecting = '';

    const handleRouteChangeStart = (route: string): void => {
      const currentRoute = route?.split('?')?.[0];

      // Only show progress when there is pathname change, route change is detected with params as well
      if (currentRoute !== routeRedirecting) {
        // Only show loading when the route takes long to load
        progressTimeout = setTimeout(() => {
          setLoading(true);

          setProgress(0);

          // this will mimic like there is some progress in the bundle fetching
          progressInterval = setInterval(() => {
            setProgress((prev) => {
              if (prev < MAX_PROGRESS_PERCENT) {
                return prev + PROGRESS_PERCENT_INCREMENT_ON_TICK;
              }
              return prev;
            });
          }, PROGRESS_TICK_INTERVAL);
        }, TIMEOUT_TO_SHOW_PROGRESS);
      }

      routeRedirecting = currentRoute;
    };

    const handleRouteChangeComplete = (): void => {
      clearInterval(progressInterval);
      clearTimeout(progressTimeout);
      setProgress(100);
      setTimeout(() => setLoading(false), 50);
    };

    // Subscribe to route change events
    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('routeChangeError', handleRouteChangeComplete);

    // Cleanup: remove event listeners when the component unmounts
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.off('routeChangeError', handleRouteChangeComplete);
    };
  }, [router.events]);

  if (isLoading) {
    return (
      <Progress top="0" style={{ zIndex: 999 }} h="2px" mih="2px" pos="fixed" left={0} w="100%" value={progress} />
    );
  }

  return null;
};
